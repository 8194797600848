


export class MetaDataModel{

  title:string;
  description:string;
  robots:string;
  keywords:string;
  ogLocale:string;
  ogType:string;
  ogTitle:string;
  ogDescription:string;
  ogUrl:string;
  ogSite_name:string;
  twitterCard:string;
  //canonicalRefLink:string
   twitterDescription:string;
   twitterTitle:string;

   image:string;
   articlePublishedTime:string;
   setImage(image:string){
     this.image=image;
   }
   setArticlePublishedTime(articlePublishedTime:string){
     this.articlePublishedTime=articlePublishedTime;
   }
  constructor(  title:string,
      description:string,
      robots:string,
      keywords:string,
      ogLocale:string,
      ogType:string,
      ogTitle:string,
      ogDescription:string,
      ogUrl:string,
      ogSite_name:string,
      twitterCard:string,
      twitterDescription:string,
      twitterTitle:string
    ){

          this.title=title,
          this.description=description,
          this.robots=robots,
          this.keywords=keywords,
          this.ogLocale=ogLocale,
          this.ogType=ogType,
          this.ogTitle=ogTitle,
          this.ogDescription=ogDescription,
          this.ogUrl=ogUrl,
          this.ogSite_name=ogSite_name,
          this.twitterCard=twitterCard,
          this.twitterDescription=twitterDescription;
         this.twitterTitle=twitterTitle;
      }


}