<div class="container-fluid">
    <div class="col-md-12 col-sm-12 about-bg">
        <div class="col-md-12 col-sm-12 text-center margin-top-10 about-heading">
            <p>About Us</p>
            <img src="/assets/images/Flipkart-Ventures-logo.gif"/>
        </div>
        <div class="col-md-12 col-sm-12 text-center about-heading-description">
            <p>The early stage investment arm of Flipkart, with a $100 million fund to back early-stage startups in India and thereby support the ecosystem to build innovative solutions for the next wave of internet users.</p>
        </div>
        <div class="col-md-12 col-sm-12 about-vision-value">
            <div class="col-md-6 col-sm-6 vision">
                <h2>Vision</h2>
                <p>To catalyse, the next wave of India’s digital disruption by backing entrepreneurs who have a strong culture fit with Flipkart, i.e. solving large, complex problems with the ability to achieve audacious outcomes.</p>
            </div>
            <div class="col-md-6 col-sm-6 value">
                <h2>Value multipliers</h2>
                <p>Being a pioneer in the Indian startup ecosystem, Flipkart can add significant value to our investee companies. Flipkart Ventures is uniquely positioned to leverage the collective experience and expertise of Flipkart leadership to guide entrepreneurs on their scale-up journey.</p>
            </div>
        </div>
    </div>
    <section class="col-md-12 text-center fk-video">
        <video autoplay="true" loop="true" muted="true" src="https://www.flipkartcareers.com/images/fk_site/Pillars4websitedesktop.mp4" class="video-hide"></video>
        <video autoplay="true" loop="true" muted="true" src="https://www.flipkartcareers.com/images/fk_site/Pillars_4_mobile.mp4" class="video-show"></video>
    </section>
    <div class="row">
        <div class="about-box col-md-12 col-sm-12">
            <div class="col-md-12 col-sm-12">
                <div class="col-md-8 col-sm-8">
                    <h2>What’s in the box</h2>
                    <p>Flipkart Ventures will assist startups with mentoring by Flipkart leaders experienced in building and scaling businesses as well as functional leaders who will impart best practices across Finance, HR, Compliance, and other verticals. Further, startups in which Flipkart Ventures has invested will have access to events and workshops to help them showcase their products and solutions.</p>
                </div>
                <div class="col-md-4 col-sm-4 about-box-image">
                    <img src="https://www.flipkartcareers.com/images/fk_site/Rally_Cry.png"/>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 about-box-card">
                <div class="col-md-3 col-sm-6">
                    <p>Access to Flipkart<br> Leadership Team</p>
                </div>
                <div class="col-md-3 col-sm-6">
                    <p>Best in Class<br> Operational Guidance</p>
                </div>
                <div class="col-md-3 col-sm-6">
                    <p>One stop shop for<br> all investments</p>
                </div>
                <div class="col-md-3 col-sm-6">
                    <p>Instant Credibility<br> and Connects</p>
                </div>
            </div>
        </div>
    </div>
</div>