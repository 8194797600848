import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { VentureHeaderComponent } from './venture-header/venture-header.component';
import { AboutComponent } from './about/about.component';
import { VentureFooterComponent } from './venture-footer/venture-footer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PeopleComponent } from './people/people.component';
import { InvestmentsComponent } from './investments/investments.component';
import { InvestmentDetailsComponent } from './investment-details/investment-details.component';
import { SEOService } from './services/seo.service';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VentureHeaderComponent,
    AboutComponent,
    VentureFooterComponent,
    PeopleComponent,
    InvestmentsComponent,
    InvestmentDetailsComponent,
    TermsComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule
  ],
  providers: [SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }