import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaDataModel } from '../models/metadata.model';
@Injectable()
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.removeTag('description')
    this.meta.addTag({ name: 'description', content: desc }, true);

  }

  updateCaseStudytDescriptionTags(metaModel: MetaDataModel) {
    this.updateTags(metaModel);
    this.meta.addTag({ property: 'og:image', content: metaModel.image });
    this.meta.addTag({ name: 'twitter:image', content: metaModel.image });
  }

  updateBlogsDescriptionTags(metaModel: MetaDataModel) {
    this.updateTags(metaModel);
    this.meta.addTag({ property: 'og:image', content: metaModel.image });
    this.meta.addTag({ name: 'twitter:image', content: metaModel.image });
    this.meta.addTag({ name: 'article:section', content: "Product" });
    this.meta.addTag({ name: 'article:published_time', content: metaModel.articlePublishedTime });
    // this.meta.addTag({name:'article:published_time',content: metaModel.image});
    // this.meta.addTag({name:'article:modified_time',content: metaModel.image});
    // this.meta.addTag({name:'og:updated_time',content: metaModel.image});


  }

  addTags(metaModel: MetaDataModel) {
    this.title.setTitle(metaModel.title);
    this.meta.addTag({ name: 'description', content: metaModel.description });
    this.meta.addTag({ name: 'robots', content: metaModel.robots });
    this.meta.addTag({ name: 'keywords', content: metaModel.keywords });
    // this.meta.updateTag({ name: 'canonical', content: metaModel.canonicalRefLink });
    this.meta.addTag({ property: 'og:locale', content: metaModel.ogLocale });
    this.meta.addTag({ property: 'og:type', content: metaModel.ogType });
    this.meta.addTag({ property: 'og:title', content: metaModel.ogTitle });
    this.meta.addTag({ property: 'og:description', content: metaModel.ogDescription });
    this.meta.addTag({ property: 'og:url', content: metaModel.ogUrl });
    this.meta.addTag({ property: 'og:site_name', content: metaModel.ogSite_name });
    this.meta.addTag({ name: 'twitter:card', content: metaModel.twitterCard });
    this.meta.addTag({ name: 'twitter:description', content: metaModel.description });
    this.meta.addTag({ name: 'twitter:title', content: metaModel.title });
  }
  updateTags(metaModel: MetaDataModel) {
    this.title.setTitle(metaModel.title);
    this.meta.updateTag({ name: 'description', content: metaModel.description });
    this.meta.updateTag({ name: 'robots', content: metaModel.robots });
    this.meta.updateTag({ name: 'keywords', content: metaModel.keywords });
    // this.meta.updateTag({ name: 'canonical', content: metaModel.canonicalRefLink });
    this.meta.updateTag({ property: 'og:locale', content: metaModel.ogLocale });
    this.meta.updateTag({ property: 'og:type', content: metaModel.ogType });
    this.meta.updateTag({ property: 'og:title', content: metaModel.ogTitle });
    this.meta.updateTag({ property: 'og:description', content: metaModel.ogDescription });
    this.meta.updateTag({ property: 'og:url', content: metaModel.ogUrl });
    this.meta.updateTag({ property: 'og:site_name', content: metaModel.ogSite_name });
    this.meta.updateTag({ name: 'twitter:card', content: metaModel.twitterCard });
    this.meta.updateTag({ name: 'twitter:description', content: metaModel.description });
    this.meta.updateTag({ name: 'twitter:title', content: metaModel.title });
  }

  updateBlogs(metaModel:MetaDataModel){
    this.updateTags(metaModel);
    this.meta.addTag({name:'article:section',content:"Product"});
  }


}