import { Component, OnInit} from '@angular/core';
import { SEOService } from './services/seo.service';
import { MetaDataModel } from './models/metadata.model';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'FlipkartVenture';
  constructor(private seoService: SEOService,private router: Router) {}
  ngOnInit() {
    this.seoService.addTags(new MetaDataModel('Flipkart Venture Capital',
      'Flipkart ventures is the venture arm of Flipkart investing in early stage startups',
      'index',
      'Flipkart, Ventures, Flipkart Ventures, Venture capital, Flipkart Venture capital, Investments, Flipkart Investments, Early stage, Flipkart Early stage investments, Flipkart Portfolio, Flipkart investment team, Corporate venture capital',
      'en_US',
      'website',
      'Flipkart Venture Capital',
      'Flipkart ventures is the venture arm of Flipkart investing in early stage startups',
      'https://ventures.flipkart.com/',
      'Flipkart Venture Capital',
      'summary',
      'Flipkart ventures is the venture arm of Flipkart investing in early stage startups',
      'Flipkart Venture Capital'));

      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
  }
}