<div class="col-md-12 margin-top-5 terms">
    <h3>Flipkart Ventures Terms of Use</h3>
    <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
    <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name [www.flipkartcareers.com] (hereinafter referred to as “Website”)</p>
    <p>The Website is owned by Flipkart Internet Private Limited a company incorporated under the Companies Act, 1956 with its registered office at Buildings Alyssa, Begonia & Clover, Embassy Tech Village, Outer Ring Road, Devarabeesanahalli Village, Bengaluru – 560103, Karnataka, India and its branch offices at 2nd Floor, Block F (Flora), Embassy Tech Village, Outer Ring Road, Devarabeesanahalli Village, Bengaluru-560103, Karnataka, India and; 447/C, 12th Main, 4th Block, Koramangala, Bengaluru-560034, Karnataka, India (hereinafter referred to as "Flipkart").</p>
    <p>Your use of the Website and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Website including the applicable policies which are incorporated herein by way of reference. Please read this page carefully. If ‘You’ do not accept the Terms of Use stated herein, please do not use the Website and its services. By using the Website, you are indicating your acceptance to be bound by the provisions of these Terms of Use and <a href="/privacy">Privacy Policy</a>. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. By mere use of the Platform, You shall be contracting with Flipkart Internet Private Limited and these terms and conditions including the policies constitute Your binding obligations, with Flipkart.  By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Flipkart Policies ((including but not limited to Privacy Policy available at Privacy) as amended from time to time.</p>
    <p>For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who is accessing or viewing the Website by providing Registration Data while registering on the Website as Registered User using the computer systems. Flipkart allows the User to surf the Websites or look for roles and opportunities without registering on the Website. The term "We", “Company” "Us", "Our" shall mean Flipkart Internet Private Limited.</p>
    <h4>Eligibility</h4>
    <p>You must be at least 18 years of age or older to visit or use the Website in any manner. By visiting or accessing the Website or accepting these Terms of Use, You represent and warrant to the Company that: (a) all registration information you submit is truthful and accurate (b) You are 18 years of age or older, at the time of registration, and that You have the right, authority and capacity to use the Website and agree to and abide by these Terms of Use. You also represent and warrant to the Company that You will use the Website in a manner consistent with any and all applicable laws and regulations.</p>
    <h4>Account and Registration</h4>
    <p>If You use the Website, You shall be responsible for maintaining the confidentiality of your Email and Password and You shall be responsible for all activities that occur under your Email and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Website and refuse to provide You with access to the Website.</p>
    <p>Your Email address is treated as Your primary identifier on the Website. It is your responsibility to ensure that Your Email address is up to date on the Website at all times. You agree to notify Us promptly if your Email address changes by updating the same on the Website.</p>
    <p>You agree that Flipkart shall not be liable or responsible for the activities or consequences of use or misuse of any information that occurs under your Account in cases, including, where You have failed to update Your Email address on the Website.</p>
    <h4>Communications</h4>
    <p>When You use the Website, share information or communicate with us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email, calls or instant messaging apps or by other modes of communication, electronic or otherwise.</p>
    <h4>Representations Regarding Your Content</h4>
    <p>You represent and warrant that: (a) your Content does not violate the privacy rights, publicity rights, copyright rights, or other rights of any person, (b) by providing or posting your Content, you do not violate any confidentiality, non-disclosure, or contractual obligations you might have towards a third party, (c) any information you provide to us is correct, and (d) any information you provide about your current, past or potential status as an user is correct and complete. Please make sure that you only provide information to the Services that you are allowed to provide without violating any obligations you might have towards a third party, including any confidentiality, non-disclosure or contractual obligations. Please do not provide any information that you are not allowed to share with others, including by contract or law; please note that any information you provide will be accessible to Flipkart’s affiliates but the ownership of the content will stay with you.</p>
    <h4>Prohibited Use</h4>
    <p>Information available on this website may be used strictly for lawful purposes only.
        By using this website, individuals agree not to do any of the following:
    </p>
    <ul>
        <li>Violate any applicable local, provincial, state, national or international law, statute, ordinance, rule or regulation;</li>
        <li>Solicit passwords or personally identifiable information from other individuals;</li>
        <li>Reproduce, copy, modify, sell, store, distribute or otherwise exploit for any commercial purposes or any component thereof (including, but not limited to any materials or information accessible through this website);</li>
        <li>Use any device, software or routine to interfere or attempt to interfere with the proper working of the website or impose a disproportionately large load on the website’s infrastructure;</li>
        <li>Constitute an act of reverse engineering, decompiling, disassembling, deciphering or otherwise attempting to derive the source code for the Site or any related technology or any part thereof;</li>
        <li>Post content on the website that contain any hyperlinks, email addresses, HTML Tags, "hidden" keywords, repetitive keywords or any keywords that are irrelevant or misleading;</li>
        <li>Use any data mining, robots or similar data gathering or extraction methods. Notwithstanding anything to the contrary contained herein, use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the website other than the search engine;</li>
        <li>Aggregate, copy or duplicate in any manner any of the content or information available on the website, other than as permitted by these Terms.</li>
    </ul>
    <h4>Sharing of information with any other individual or Party</h4>
    <ul>
        <li>You are not authorized to share your password or other account access information with any other individual, party, temporarily or permanently, and breach of this obligation may result in disabling your account and services.</li>
        <li>Flipkart prohibits sharing of login /passwords with different entity/individual. In the event of any breach of security or unauthorized use of your login ID / Account you must notify Flipkart immediately. We will not be liable for any loss caused by any unauthorized use of your login ID / Account.</li>
    </ul>
    <h4>General</h4>
    <p>The Company makes no claims that the content may be lawfully viewed or accessed outside of India. Access to the Website may not be legal by certain persons or in certain countries. Your access to the Website is at your own risk and You are responsible for compliance with the laws of respective State" jurisdiction as may be applicable to You. These Terms of Use are governed by the laws of India, or any applicable laws without respect to its conflict of laws principles. Jurisdiction for any claims arising under this agreement shall lie exclusively with the Courts at Bangalore, India. If any provision of these Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any term of these Terms of Use shall be deemed a further or continuing waiver of such term or any other term. No changes to these Terms of Use shall be made except by a revised posting on this page.d
        Subject to your compliance with these Terms, we grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to: (i) access and use the Website on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal, noncommercial use. Any rights not expressly granted herein are reserved by Flipkart and Flipkart’s licensors.
    </p>
    <p>WE MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED AS PART OF THE SERVICES FOR ANY PURPOSE. ALL SUCH DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. WE HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT SHALL WE BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION AVAILABLE FROM THE SERVICES.</p>
    <p>THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE SERVICES COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. WE MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S) AND/OR THE PROGRAM(S) DESCRIBED HEREIN AT ANY TIME.</p>
    <p>YOU AGREE TO INDEMNIFY AND HOLD FLIPKART AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS HARMLESS FROM ANY AND ALL CLAIMS, DEMANDS, LOSSES, LIABILITIES, AND EXPENSES (INCLUDING ATTORNEYS’ FEES) ARISING OUT OF OR IN CONNECTION WITH: (I) YOUR USE OF THE SERVICES OR SERVICES OR GOODS OBTAINED THROUGH YOUR USE OF THE SERVICES; (II) YOUR BREACH OR VIOLATION OF ANY OF THESE TERMS; (III) FLIPKART’S USE OF YOUR USER CONTENT; OR (IV) YOUR VIOLATION OF THE RIGHTS OF ANY THIRD PARTY, INCLUDING THIRD PARTY PROVIDERS.</p>
    <h4>Grievance Redressal</h4>
    <p>If you have any feedbacks, suggestions or any complaints, please contact our Grievance Officer at</p>
    <p>Name: Shivani Kulkarni<br>
        Designation: Senior Manager<br>
        Address: ​Flipkart Internet Pvt Ltd.<br>
        Embassy Tech Village, Bellandur,<br>
        Devarabeesanahalli Village,<br>
        Bengaluru District,<br>
        Karnataka, India, 560103.<br>
        Email ID: Ventures@flipkart.com<br>
        Time:  ​Mon - Fri (9:00 - 18:00)</p>
</div>