import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.PeopleFilter("all");
  }
  
  isShown: boolean = false ;
  toggleShow() {
    this.isShown = ! this.isShown;
  }

  PeopleFilter(c: string) {
    var btnContainer = document.getElementById("myBtnContainer");
    var btns = btnContainer.getElementsByClassName("button");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function(){
        var current = document.getElementsByClassName("act");
        current[0].className = current[0].className.replace(" act", "");
        this.className += " act";
      });
    }

    var x: string | any[] | HTMLCollectionOf<Element>, i: number;
    x = document.getElementsByClassName("filterDiv");
    if (c == "all") {
      c = "";
      this.isShown = true;
    }else{this.isShown = false;};
    for (i = 0; i < x.length; i++) {
      this.RemoveDiv(x[i], "show");
      if (x[i].className.indexOf(c) > -1) this.AddDiv(x[i], "show");
    }
  }

  AddDiv(element: Element, name: string) {
    var i: number, arr1: string | any[], arr2: string | any[];
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
    }
  }

  RemoveDiv(element: Element, name: string) {
    var i: number, arr1: any[], arr2: string | any[];
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);     
      }
    }
    element.className = arr1.join(" ");
  }

}
