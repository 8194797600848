<div class="feedback-slides margin-top-5">
    <div class="client-feedback">
        <ngx-slick-carousel 
            class="carousel feedback" 
            #slickModal="slick-carousel" 
            [config]="imagesSlider">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <img src="{{ slide.img }}" alt="" width="100%">
                <div class="banner-content">
                    <p>FLIPKART STORIES | {{ slide.publish_date }}</p>
                    <h1>{{ slide.heading }}</h1>
                    <a href="{{ slide.link }}"><span><img src="/assets/images/view-all-arrow.png"></span><span>Read More</span></a>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>
<!-- <div class="client-thumbnails">
    <ngx-slick-carousel 
        class="carousel thumbs" 
        #slickModal="slick-carousel" 
        [config]="thumbnailsSlider ">
        <div ngxSlickItem *ngFor="let slide of slides; let i = index" class="slide">
            <div class="thumbnail-content">
                <div class="thumbnail-count">{{ i+1 }}</div>
                <div class="thumbnail-story">
                    <div>{{ slide.heading }}</div>
                    <div>{{ slide.publish_date }}</div>
                </div>
            </div>
        </div>
    </ngx-slick-carousel>
</div> -->
<div class="col-md-12 home-bg">
    <div class="col-md-12 team">
        <!-- <div class="col-md-6">
            <div class="grid-container">
                <img class="item1" src="/assets/images/Ravi.png">
                <img class="item2" src="/assets/images/Chinmay_Katdare.jfif">
                <img class="item3" src="/assets/images/Aishwarya.jpg"> 
                <img class="item4" src="/assets/images/Pursottam-Soni.jpg">
                <img class="item4" src="/assets/images/Shivani.jpg">
            </div>
        </div> -->
        <div class="col-md-12 team-content">
            <h5>MEET OUR TEAM.</h5>
            <h1>Together, we are more than <br>the sum of our individual contributions.</h1>
            <a href="/people"><span><img src="/assets/images/view-all-arrow.png"></span>View all</a>
        </div>
    </div>
    <div class="col-md-12 home-about-bg">
        <div class="col-md-12 home-about">
            <p>About us</p>
            <h2>Great businesses set the vision for a better future. The right innovation partner takes them there.</h2>
            <div>
                <p>Flipkart Ventures, the investment arm of Flipkart, is a $100 million fund founded on a vision to back early-stage startups in India and thereby help support the ecosystem to build innovative solutions for the next wave of internet users.</p>
                <a href="/about"><span><img src="/assets/images/view-all-arrow.png"></span><span>Know More</span></a>
            </div>
        </div>
        
    </div>
</div>
<div class="col-md-12 our-investment">
    <div class="col-md-6">
        <h5>OUR INVESTMENTS</h5>
        <h1>Building the future always starts with thinking about it.</h1>
    </div>
    <div class="col-md-6 view-investment">
        <a href="/investments">VIEW OUR INVESTMENTS</a>
    </div>
</div>
<div class="col-md-12 investment-carousel">
    <ngx-slick-carousel 
        class="carousel"
        [config]="investmentSlider">
        <div ngxSlickItem *ngFor="let slide of investmentSlides; let i = index" class="investment-slide">
            <img src="{{ slide.img }}" alt="" width="100%">
            <div class="col-md-12 investment-content">
                <p>{{ slide.description }}</p>
                <a href="/investment-details/{{ i+1 }}"><span><img src="/assets/images/view-all-arrow.png"></span><span>Read More</span></a>
            </div>
        </div>
    </ngx-slick-carousel>
</div>