import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venture-header',
  templateUrl: './venture-header.component.html',
  styleUrls: ['./venture-header.component.scss']
})
export class VentureHeaderComponent implements OnInit {
  hamburgerOpend: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  onHamburgerClicked() {
    if (!this.hamburgerOpend)
      this.hamburgerOpend = true;
    else this.hamburgerOpend = false
  }

  about(){
    this.hamburgerOpend = false;
  }

  people(){
    this.hamburgerOpend = false;
  }

  investments(){
    this.hamburgerOpend = false;
  }
}