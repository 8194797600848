<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.0/css/all.css"
      integrity="sha384-lKuwvrZot6UHsBSfcMvOkWwlCMgc0TaWr+30HWe3a4ltaBwTZhyTEggF5tJv8tbt" crossorigin="anonymous">
<div class="container-fluid margin-top-10 text-center">
    <h4>MEET OUR TEAM.</h4>
    <h1>Flipkart Ventures is led by a core investment team and supported with expertise from senior Flipkart
        leaders</h1>
    <img src="/assets/images/people-banner.png"/>
</div>
<div class="col-md-12 text-center">
    <div id="myBtnContainer">
        <button class="button act" (click)="PeopleFilter('all')"> All</button>
        <button class="button" (click)="PeopleFilter('investment')"> Investment team</button>
        <button class="button" (click)="PeopleFilter('mentors')"> Flipkart Advisors & Mentors</button>
        <!-- <button class="button" (click)="PeopleFilter('technology')"> Technology</button>
        <button class="button" (click)="PeopleFilter('finance')"> Finance</button>
        <button class="button" (click)="PeopleFilter('people_support')"> People / Support Functions</button> -->
    </div>

    <div class="contentSection">
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <!-- <div class="heading"> -->
                <!-- <p>People</p> -->
                <h2>Investment team</h2>
            </div>
            <!--            <div class="filterDiv investment">
                            <img src="/assets/images/Aishwarya.jpg"/>
                            <div>
                                <div class="name-link">
                                    <h3>Aishwarya Kalakata</h3>
                                    <a href="https://www.linkedin.com/in/aishwaryakalakata/" target="_blank"><i
                                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                                </div>
                                <p>Director - Flipkart Ventures</p>
                                <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal3"><img
                                        src="/assets/images/view-all-arrow.png"></a>
                            </div>
                        </div>-->
            <div class="filterDiv investment">
                <img src="/assets/images/lubna.jpeg" style="object-position: top;"/>
                <div>
                    <div class="name-link">
                        <h3>Lubna Ahmed</h3>
                        <a href="https://www.linkedin.com/in/lubnaahmed/" target="_blank"><i class="fab fa-linkedin"
                                                                                             style="font-size: 30px;"></i></a>
                    </div>
                    <p>Senior Director - Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal2"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <!--            <div class="filterDiv investment">
                            <img src="/assets/images/Monika-Kalyani.jpeg"/>
                            <div>
                                <div class="name-link">
                                    <h3>Monika Kalyani</h3>
                                    <a href="https://www.linkedin.com/in/monikakalyani/" target="_blank"><i class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                                </div>
                                <p>Associate Director - Flipkart Ventures</p>
                                <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal4"><img src="/assets/images/view-all-arrow.png"></a>
                            </div>
                        </div>-->
            <div class="filterDiv investment">
                <img src="/assets/images/Pursottam-Soni1.jpg"/>
                <div>
                    <div class="name-link">
                        <h3>Pursottam Soni</h3>
                        <a href="https://www.linkedin.com/in/pursottam-soni-56341457/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p> Senior Manager - Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal4"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv investment">
                <img src="/assets/images/Ravi.png"/>
                <div>
                    <div class="name-link">
                        <h3>Ravi Iyer</h3>
                        <a href="https://www.linkedin.com/in/ravi-iyer-24180a12/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Senior Vice President and Head, Corporate Development & Strategic Partnerships, Flipkart</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal1"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv investment">
                <img src="/assets/images/Tessa-Thomas1.png" style="object-position: top;"/>
                <div>
                    <div class="name-link">
                        <h3>Tessa Thomas</h3>
                        <a href="http://www.linkedin.com/in/tessa-thomas-2017/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px; "></i></a>
                    </div>
                    <p>Senior Manager - Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal5"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <!-- <p>People</p> -->
                <h2>Flipkart Advisors & Mentors</h2>
            </div>
            <!--   <div class="filterDiv mentors">
                   <img src="/assets/images/AdarshMenon.jpeg"/>
                   <div>
                       <div class="name-link">
                           <h3>Adarsh Menon</h3>
                           <a href="https://www.linkedin.com/in/adarsh-menon-8a35652/" target="_blank"><i class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                       </div>
                       <p>SVP, New Businesses</p>
                       &lt;!&ndash; <h5>On 'Understanding your customers and business development'</h5> &ndash;&gt;
                   </div>
               </div>-->
            <div class="filterDiv mentors">
                <img src="/assets/images/anand.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Anand <br>Lakshminarayanan</h3>
                        <a href="https://www.linkedin.com/in/anandlak/" target="_blank"><i class="fab fa-linkedin"
                                                                                           style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP, Flipkart Commerce Cloud</p>
                    <!-- <h5>On 'Building tech businesses'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/bharath.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Bharath Ram</h3>
                        <a href="https://www.linkedin.com/in/rbharathram/" target="_blank"><i class="fab fa-linkedin"
                                                                                              style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP & Product Head</p>
                    <!-- <h5>On 'Product Market Fit'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Dinkar.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Dinkar Ayilavarapu</h3>
                        <a href="https://www.linkedin.com/in/dinkar-ayilavarapu-248a19/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Head of Corporate Strategy and Flipkart Wholesale</p>
                    <!-- <h5>On 'All things Growth'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Hemant.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Hemant Badri</h3>
                        <a href="https://www.linkedin.com/in/hemant-badri-34a3446/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP & Head of Supply Chain</p>
                    <!-- <h5>On 'Scaling Operations'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/krishna.png"/>
                <div>
                    <div class="name-link">
                        <h3>Krishna Raghavan</h3>
                        <a href="https://www.linkedin.com/in/krishnaraghavan/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Chief People Officer</p>
                    <!-- <h5>On 'Building the right talent'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/mayur.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Mayur Datar</h3>
                        <a href="https://www.linkedin.com/in/mayur-datar-b0a65018/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Chief Data Scientist</p>
                    <!-- <h5>On 'Scaling Tech & Architecture'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Naren.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Naren Ravula</h3>
                        <a href="https://www.linkedin.com/in/narenravula/" target="_blank"><i class="fab fa-linkedin"
                                                                                              style="font-size: 30px;"></i></a>
                    </div>
                    <p>Head of Product Strategy, Deployment & Flipkart Labs</p>
                    <!-- <h5>On 'All things Growth'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/prakash.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Prakash Sikaria</h3>
                        <a href="https://www.linkedin.com/in/prakashsikaria/" target="_blank"><i class="fab fa-linkedin"
                                                                                                 style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP Growth</p>
                    <!-- <h5>On 'All things Growth'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/pramod.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Pramod Jain</h3>
                        <a href="https://www.linkedin.com/in/pramod-jain-01949111/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP Enterprise Risk Mgmt & Corporate Taxation</p>
                    <!-- <h5>'On Corporate Building Blocks'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Pranav.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Pranav Saxena</h3>
                        <a href="https://www.linkedin.com/in/pranav-saxena-5226a4/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Chief Product and Technology Officer, Flipkart Healthplus</p>
                    <!-- <h5>On 'All things Growth'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Rakesh.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Rakesh Krishnan</h3>
                        <a href="https://www.linkedin.com/in/rakesh-krishnan-039b381b/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Vice President and Head of Marketplace</p>
                    <!-- <h5>On 'All things Growth'</h5> -->
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/sakait.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Sakait Chaudhury</h3>
                        <a href="https://www.linkedin.com/in/sakait-chaudhary-8780a9122/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>CFO</p>
                    <!-- <h5>On 'Startup Finanace Management'</h5> -->
                </div>
            </div>
        </div>


        <!-- <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <p>People</p>
                <h2>Technology</h2>
            </div>
            <div class="filterDiv technology">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Ravish Sinha</h3>
                    <p>Senior Vice President - Shopping Experience, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv technology">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Pranav Saxena</h3>
                    <p>Vice President - Product & Engineering, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <p>People</p>
                <h2>Finance</h2>
            </div>
            <div class="filterDiv finance">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Ramesh Bafna</h3>
                    <p>Senior Vice President & Chief Financial Officer, Myntra</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv finance">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Pratik Dalal</h3>
                    <p>Senior Director - Business Finance, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv finance">
                <img src="/assets/images/meet1.png"/>
                <div>
                    <h3>Lalit Bhimani</h3>
                    <p>Senior Director - Business Finance, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <p>People</p>
                <h2>People / Support Functions</h2>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Daniel De La Garza</h3>
                    <p>Senior Vice President and Chief Ethics & Compliance Officer, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Kapil Vardhan</h3>
                    <p>Senior Director - Human Resources, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Govindraj MK</h3>
                    <p>Senior Director - Supply Chain & Leadership Hiring, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet1.png"/>
                <div>
                    <h3>Prajakta Kanaglekar</h3>
                    <p>Senior Director - Organization & Talent Development, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div> -->
    </div>

    <div class="modal fade" id='People_modal1' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Ravi.png" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Ravi Iyer</h1>
                        <label>Senior Vice President and Head, Corporate Development & Strategic Partnerships,
                            Flipkart</label>
                        <br><a href="https://www.linkedin.com/in/ravi-iyer-24180a12/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Ravi Iyer has been working with Flipkart over the last 8 years in multiple roles , prior to
                            that spent 12 years plus in ITC Ltd- FMCG business. Currently Heads Corporate Development,
                            Partnerships , IR and Corporate Finance.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id='People_modal2' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/lubna.jpeg" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Lubna Ahmed</h1>
                        <label>Senior Director - Flipkart Ventures</label>
                        <br><a href="https://www.linkedin.com/in/lubnaahmed/" target="_blank"><i class="fab fa-linkedin"
                                                                                                 style="font-size: 30px;"></i></a>
                        <div>Lubna leads technology investments, acquisitions and investor relations at Flipkart. She
                            has
                            over 14 years experience in investment banking and corporate development in global markets
                            and
                            tech start ups. In her last role at Ola, she headed Corporate Finance & Investor Relations
                            where
                            she raised over $1bn in private capital and launched Ola's international business. Prior to
                            that, Lubna spent over 9 years in investment banking with Avendus Capital, Credit Suisse and
                            Deutsche Bank in Mumbai, Singapore and New York.
                            She has received her MBA from Chicago Booth, B.Tech from IIT Bombay and is a CFA
                            Charterholder.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--   <div class="modal fade" id='People_modal3' style="display: none;">
           <div class="modal-dialog">
               <div class="modal-content">
                   <div class="col-md-12 col-sm-12 modal-body">
                       <span class="close" data-dismiss="modal">×</span>
                       <div class="col-md-4 col-sm-12 leader-modal_img">
                           <img src="/assets/images/Aishwarya.jpg" alt="leader-image">
                       </div>
                       <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                           <h1>Aishwarya Kalakata</h1>
                           <label>Director - Flipkart Ventures</label>
                           <br><a href="https://www.linkedin.com/in/aishwaryakalakata/" target="_blank"><i
                               class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                           <div>Aishwarya has worked with Flipkart for 4 years in multiple roles. She has helped launch
                               Flipkart Leap, the tech startup accelerator of Flipkart. Along with Leap, she focuses on
                               seed
                               stage investments for Flipkart Ventures. In her earlier roles, she has led Product
                               Innovation
                               and Strategy Planning for the organization. Prior to Flipkart, she has worked across
                               multiple
                               industries in roles ranging from sales, marketing, supply chain to process consulting and
                               managing global transformational technology projects.
                               She holds a Computer Science degree from IIIT Hyderabad and an MBA from IIM Bangalore.
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>-->
    <!--    <div class="modal fade" id='People_modal4' style="display: none;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="col-md-12 col-sm-12 modal-body">
                        <span class="close" data-dismiss="modal">×</span>
                        <div class="col-md-4 col-sm-12 leader-modal_img">
                            <img src="/assets/images/Monika-Kalyani.jpeg" alt="leader-image">
                        </div>
                        <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                            <h1>Monika Kalyani</h1>
                            <label>Associate Director - Flipkart Ventures</label>
                            <br><a href="https://www.linkedin.com/in/monikakalyani/" target="_blank"><i class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                            <div>Monika joined Flipkart in early 2022. Prior to Flipkart, she led investments and acquisitions for FirstMeridian, a roll-up investment vehicle funded by Goldman Sachs, Janchor Partners and Samara Capital. In her earlier role, she was an Investment Banker working with Avendus Capital, focussing on Digital & Tech domain. At Avendus, she has worked across multiple fundraising and acquisition transactions. Monika completed her MBA from SP Jain, Mumbai and is currently working towards her CFA Charter.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    <div class="modal fade" id='People_modal4' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Pursottam-Soni1.jpg" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Pursottam Soni</h1>
                        <label>Senior Manager - Flipkart Venturess</label>
                        <br><a href="https://www.linkedin.com/in/pursottam-soni-56341457/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Pursottam joined Flipkart in 2021 and is currently a Manager with the Ventures team.
                            He focuses on early-stage investments in Fintech, Consumer Tech, Logistics, Commerce and
                            Blockchain. He previously worked with J.P. Morgan in the India Investment Banking team where
                            he
                            was involved in several Equity Private Placements and IPOs in the growth tech space. Deals
                            include Paytm, OYO and other late stage growth tech companies.
                            Pursottam did his undergraduate from IIT Kharagpur and MBA from IIM Ahmedabad.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id='People_modal5' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Tessa-Thomas1.png" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Tessa Thomas</h1>
                        <label>Senior Manager - Flipkart Venturess</label>
                        <br><a href="http://www.linkedin.com/in/tessa-thomas-2017/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Tessa joined Flipkart in 2020 in the Corporate Strategy function where she worked on
                            Fintech,
                            Advertising, and Customer Strategy.
                            As part of Flipkart Ventures, she focuses on early-stage investments in Fintech,
                            ClimateTech,
                            and E-commerce Enablement.
                            Prior to Flipkart, she worked across multiple roles ranging from business development, and
                            category management to strategy consulting in both early and mid-stage start-ups like
                            Headout
                            and Razorpay.
                            She holds an MBA from ISB, Hyderabad, and an undergraduate degree in Economics (Hons) from
                            St.Stephen's College, Delhi.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
