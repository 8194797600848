<div class="col-md-12 margin-top-5 privacy">
    <h3>PRIVACY POLICY</h3>
    <p>We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy describes how Flipkart Internet Private Limited and its affiliates (collectively “Flipkart, we, our, us”) collect, use, share or otherwise process your personal information through Flipkart website <a href="https://ventures.flipkart.com/">https://ventures.flipkart.com/</a>, and m-site (hereinafter referred to as the “Platform”).</p>
    <p>The products and services offered under this Platform are intended only for business within India. The personal information that we process about you may primarily be stored and processed in India and may have data protection laws that are different from those that apply in the country in which you are located.  By visiting this Platform, contacting us, providing your information or availing out product/service, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the <a href="/terms">Terms of Use</a> and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform.</p>
    <h4>Collection of Your Information</h4>
    <p>In general, you can browse the Platform without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. We only collect or process the information that is needed for legitimate, business, contractual and lawful purposes only. When you choose to visit our Platform or contact us for availing our product/services, we may process your name, email or other business contact information such as the information available in your signature. The information that you share with us is voluntary and is intended for legitimate business purposes only.</p>
    <p>For all the information you have shared in public domains (such as social media websites), we understand that these information were shared voluntarily and Flipkart ascertain no responsibility for correctness and accuracy of such information. We do use your contact information to send you communications and other marketing related information that are aligned to your interests unless you opt-out from receiving marketing or promotional information. If you receive an email, a call from a person/association claiming to be from Flipkart seeking personal or sensitive personal information like name, contact details, address, financial instruments, identifiers, etc., we request you to never provide such information. If you have already revealed such information, report it immediately to an appropriate law enforcement agency.</p>
    <p>If you engage with our platform on social media (for instance liking a post, reposting, commenting, retweeting, mentioning, or following us), we'll have access to your interactions and profile information. We'll retain information even if you remove it from the social media site. We may store certain information posted on these social media platforms such as comments, direct messages, media comments, profile image, order details track your buying behaviour, preferences, and other information that you choose to provideIf you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information.</p>
    <p>If you are visiting any third party websites by clicking on a url available in our Platform, such third-party websites may collect your personal information directly from you, and you will be governed by their privacy policies. Flipkart shall not be responsible for the third-party business partner’s privacy practices or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information to them.</p>
    <h4>Use of Demographic / Profile Data / Your Information</h4>
    <p>We use your personal information to provide the product and services you request or to study the specific requirements from you. To the extent we use your personal information to market to you or send communications such as updates inline to our program, we will provide you the ability to opt-out of such uses. We use your personal information for enhancing customer experience; resolve disputes; troubleshoot problems;  send verifications mails to ensure your identity; help promote a safe service; measure consumer interest in our products and services; inform you about online and offline offers, products, services, and updates; customize and enhance your experience; conduct surveys for internal purposes; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; exercising a right or obligation conferred or imposed by law, including responding to request and legal demands; and as otherwise described to you at the time of collection of information.</p>
    <p>We may use your personal information to  post success stories obtained from you in our platform or to enhance your experience on the Platform and provide you access to the services being offered by us. You understand that your access to these products/services may be affected in the event consent is not provided to us.</p>
    <p>In our efforts to continually improve our product and service offerings, we and our affiliates collect and analyse demographic and profile data about our users' activity on our Platform. We identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify you and to gather broad demographic information.</p>
    <h4>Cookies</h4>
    <p>We use data collection devices such as "cookies" on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal information. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics help us understand how our customers use the site. You can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
    <h4>Sharing of personal information</h4>
    <p>We may share personal information internally within Flipkart group companies, affiliates, partners, related companies and with other third parties for purposes of providing products and services offered by them, such as, development and maintenance of our applications or systems, help you subscribe to web and push notifications etc. </p>
    <p>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>
    <p>We and our affiliates may share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or reorganization, amalgamation, restructuring of business. Should such a transaction occur that another business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to your personal information. Due to the international nature of our business and in connection with the purposes set out in this Privacy Policy, we may share your personal information internationally with our group companies, partners or legal authorities as established or located in countries that may have different laws and data protection compliance requirements to those that apply in the country in which you are located. We would ensure appropriate security measures while we transfer these information.</p>
    <h4>Links to Other Sites</h4>
    <p>Our Platform may provide links to other websites or applications  that may collect personal information about you. We are not responsible for the privacy practices or the content of those linked websites.</p>
</div>