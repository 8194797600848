<div class="test">
    <input type="checkbox" class="menu-toggle" id="menu-toggle">
    <div class="mobile-bar" (click)="onHamburgerClicked()">
        <label for="menu-toggle" class="menu-icon">
            <span></span>
        </label>
    </div>
    <header class="header" [ngClass]="{'responsive': hamburgerOpend, 'header_navigate': !hamburgerOpend}">
        <nav>
            <ul>
                <li routerLinkActive="active"><a (click)="about()" [routerLink]="['about']">About</a></li>
                <li routerLinkActive="active"><a (click)="people()" [routerLink]="['people']">People</a></li>
                <li routerLinkActive="active"><a (click)="investments()" [routerLink]="['investments']">Investments</a></li>
                <li><a href="https://www.flipkartleap.com/">Flipkart Leap</a></li>
                <li><a href="mailto:ventures@flipkart.com" class="btn header-button"><span>PITCH TO US</span></a></li>
            </ul>
        </nav>
    </header>
    <a class="navbar-brand" href="/home" style="padding:0px;">
        <img src="/assets/images/FK_Ventures-Logo.png">
    </a>
</div>