<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.0/css/all.css"
      integrity="sha384-lKuwvrZot6UHsBSfcMvOkWwlCMgc0TaWr+30HWe3a4ltaBwTZhyTEggF5tJv8tbt" crossorigin="anonymous">
<div class="container-fluid margin-top-10 text-center">
    <h4>MEET OUR TEAM.</h4>
    <h1>Flipkart Ventures is led by a core investment team and supported with expertise from senior Flipkart
        leaders</h1>
    <img src="/assets/images/people-banner.png"/>
</div>
<div class="col-md-12 text-center">
    <div id="myBtnContainer">
        <button class="button act" (click)="PeopleFilter('all')"> All</button>
        <button class="button" (click)="PeopleFilter('investment')"> Investment team</button>
        <button class="button" (click)="PeopleFilter('mentors')"> Flipkart Advisors & Mentors</button>
        <!-- <button class="button" (click)="PeopleFilter('technology')"> Technology</button>
        <button class="button" (click)="PeopleFilter('finance')"> Finance</button>
        <button class="button" (click)="PeopleFilter('people_support')"> People / Support Functions</button> -->
    </div>

    <div class="contentSection">
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <h2>Investment team</h2>
            </div>
            <div class="filterDiv investment">
                <img src="/assets/images/Ravi.png"/>
                <div>
                    <div class="name-link">
                        <h3>Ravi Iyer</h3>
                        <a href="https://www.linkedin.com/in/ravi-iyer-24180a12/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP and Head, Corporate Development & Strategic Partnerships, Flipkart</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal1"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv investment">
                <img src="/assets/images/lubna.jpeg" style="object-position: top;"/>
                <div>
                    <div class="name-link">
                        <h3>Lubna Ahmed</h3>
                        <a href="https://www.linkedin.com/in/lubnaahmed/" target="_blank"><i class="fab fa-linkedin"
                                                                                             style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP - Corporate Development</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal2"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv investment">
                <img src="/assets/images/bhargav.jpg"/>
                <div>
                    <div class="name-link">
                        <h3>Bhargav Teja</h3>
                        <a href="https://www.linkedin.com/in/bhargavtejak/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Manager, Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal3"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv investment">
                <img src="/assets/images/Mayank.jpeg">
                <div>
                    <div class="name-link">
                        <h3>Mayank Jain</h3>
                        <a href="https://www.linkedin.com/in/jain-mayank/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal4"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <!-- <div class="filterDiv investment">
                <img src="/assets/images/Pursottam-Soni1.jpg"/>
                <div>
                    <div class="name-link">
                        <h3>Pursottam Soni</h3>
                        <a href="https://www.linkedin.com/in/pursottam-soni-56341457/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p> Senior Manager - Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal4"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div> -->
            <!-- <div class="filterDiv investment">
                <img src="/assets/images/Tessa-Thomas1.png" style="object-position: top;"/>
                <div>
                    <div class="name-link">
                        <h3>Tessa Thomas</h3>
                        <a href="http://www.linkedin.com/in/tessa-thomas-2017/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px; "></i></a>
                    </div>
                    <p>Senior Manager - Flipkart Ventures</p>
                    <a class="about-leaders__box" href="#" data-toggle="modal" data-target="#People_modal5"><img
                            src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div> -->
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <h2>Flipkart Advisors & Mentors</h2>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Hemant.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Hemant Badri</h3>
                        <a href="https://www.linkedin.com/in/hemant-badri-34a3446/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP & Head of Supply Chain</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/nandita-sinha.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Nandita Sinha</h3>
                        <a href="https://www.linkedin.com/in/nandita-sinha-3020275/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>CEO, Myntra</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/prakash.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Prakash Sikaria</h3>
                        <a href="https://www.linkedin.com/in/prakashsikaria/" target="_blank"><i class="fab fa-linkedin"
                                                                                                 style="font-size: 30px;"></i></a>
                    </div>
                    <p>Founder - Super.Money, SVP growth </p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/pramod.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Pramod Jain</h3>
                        <a href="https://www.linkedin.com/in/pramod-jain-01949111/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP Enterprise Risk Mgmt & Corporate Taxation</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/pranav-tiwari.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Pranav Kumar Tiwari</h3>
                        <a href="https://www.linkedin.com/in/pranav-tiwari-3204085/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP, Product & Management</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/rajneesh.jpg"/>
                <div>
                    <div class="name-link">
                        <h3>Rajneesh Kumar</h3>
                        <a href="https://www.linkedin.com/in/rajneeshkumar/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP & Chief Corporate Affairs Officer</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/ramesh-gururaj.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Ramesh Gururaja</h3>
                        <a href="https://www.linkedin.com/in/rameshg/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP, Customer and Growth</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/ravi-vijayaraghavan.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Ravi Vijaya Raghavan</h3>
                        <a href="https://www.linkedin.com/in/ravivijayaraghavan/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP, Chief Data and Analytics Officer</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/sakait.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Sakait Chaudhury</h3>
                        <a href="https://www.linkedin.com/in/sakait-chaudhary-8780a9122/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP, Head of Marketplace</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/sriram.png"/>
                <div>
                    <div class="name-link">
                        <h3>Sriram Venkataraman</h3>
                        <a href="https://www.linkedin.com/in/sriram-venkataraman-a58986/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>SVP and Group CFO</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Dinkar.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Dinkar Ayilavarapu</h3>
                        <a href="https://www.linkedin.com/in/dinkar-ayilavarapu-248a19/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Head of Corporate Strategy and Flipkart Wholesale</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/gaurav-arora.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Gaurav Arora</h3>
                        <a href="https://www.linkedin.com/in/gaurav-arora/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP FPG Biz</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/kanchan.png"/>
                <div>
                    <div class="name-link">
                        <h3>Kanchan Mishra</h3>
                        <a href="https://www.linkedin.com/in/kanchanmishra/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP, Head of FK Minutes</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Mani-Bhushan.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Mani Bhushan</h3>
                        <a href="https://www.linkedin.com/in/manibhushan78" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP Ekart Business</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/manjari.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Manjari Singhal</h3>
                        <a href="https://www.linkedin.com/in/manjari-singhal-0a06157/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP, Head of BGMH</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/rahat-patel.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Rahat Patel</h3>
                        <a href="https://www.linkedin.com/in/rahatpatel/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP, Head of Loyalty</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/Rakesh.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Rakesh Krishnan</h3>
                        <a href="https://www.linkedin.com/in/rakesh-krishnan-039b381b/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP and Head of Marketplace</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/ravi-chandrashekar.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Ravi Chandrasekhar</h3>
                        <a href="https://www.linkedin.com/in/ravi-chandrasekhar-b14416a0/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>Head - Flipkart Labs</p>
                </div>
            </div>
            <div class="filterDiv mentors">
                <img src="/assets/images/suresh-john.jpeg"/>
                <div>
                    <div class="name-link">
                        <h3>Suresh John</h3>
                        <a href="https://www.linkedin.com/in/suresh-john-93b1a714/" target="_blank"><i
                                class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                    </div>
                    <p>VP, CX</p>
                </div>
            </div> 
        </div>
        <!-- <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <p>People</p>
                <h2>Technology</h2>
            </div>
            <div class="filterDiv technology">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Ravish Sinha</h3>
                    <p>Senior Vice President - Shopping Experience, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv technology">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Pranav Saxena</h3>
                    <p>Vice President - Product & Engineering, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <p>People</p>
                <h2>Finance</h2>
            </div>
            <div class="filterDiv finance">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Ramesh Bafna</h3>
                    <p>Senior Vice President & Chief Financial Officer, Myntra</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv finance">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Pratik Dalal</h3>
                    <p>Senior Director - Business Finance, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv finance">
                <img src="/assets/images/meet1.png"/>
                <div>
                    <h3>Lalit Bhimani</h3>
                    <p>Senior Director - Business Finance, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12">
            <div class="heading" *ngIf="isShown">
                <p>People</p>
                <h2>People / Support Functions</h2>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Daniel De La Garza</h3>
                    <p>Senior Vice President and Chief Ethics & Compliance Officer, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Kapil Vardhan</h3>
                    <p>Senior Director - Human Resources, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet4.png"/>
                <div>
                    <h3>Govindraj MK</h3>
                    <p>Senior Director - Supply Chain & Leadership Hiring, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
            <div class="filterDiv people_support">
                <img src="/assets/images/meet1.png"/>
                <div>
                    <h3>Prajakta Kanaglekar</h3>
                    <p>Senior Director - Organization & Talent Development, Flipkart</p>
                    <a href=""><img src="/assets/images/view-all-arrow.png"></a>
                </div>
            </div>
        </div> -->
    </div>

    <div class="modal fade" id='People_modal1' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Ravi.png" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Ravi Iyer</h1>
                        <label>Senior Vice President and Head, Corporate Development & Strategic Partnerships,
                            Flipkart</label>
                        <br><a href="https://www.linkedin.com/in/ravi-iyer-24180a12/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Ravi Iyer has been working with Flipkart over the last 8 years in multiple roles , prior to
                            that spent 12 years plus in ITC Ltd- FMCG business. Currently Heads Corporate Development,
                            Partnerships , IR and Corporate Finance.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id='People_modal2' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/lubna.jpeg" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Lubna Ahmed</h1>
                        <label>Vice President - Corporate Development</label>
                        <br><a href="https://www.linkedin.com/in/lubnaahmed/" target="_blank"><i class="fab fa-linkedin"
                                                                                                 style="font-size: 30px;"></i></a>
                        <div>Lubna leads technology investments, acquisitions and investor relations at Flipkart. She
                            has
                            over 14 years experience in investment banking and corporate development in global markets
                            and
                            tech start ups. In her last role at Ola, she headed Corporate Finance & Investor Relations
                            where
                            she raised over $1bn in private capital and launched Ola's international business. Prior to
                            that, Lubna spent over 9 years in investment banking with Avendus Capital, Credit Suisse and
                            Deutsche Bank in Mumbai, Singapore and New York.
                            She has received her MBA from Chicago Booth, B.Tech from IIT Bombay and is a CFA
                            Charterholder.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal fade" id='People_modal4' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Pursottam-Soni1.jpg" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Pursottam Soni</h1>
                        <label>Senior Manager - Flipkart Venturess</label>
                        <br><a href="https://www.linkedin.com/in/pursottam-soni-56341457/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Pursottam joined Flipkart in 2021 and is currently a Manager with the Ventures team.
                            He focuses on early-stage investments in Fintech, Consumer Tech, Logistics, Commerce and
                            Blockchain. He previously worked with J.P. Morgan in the India Investment Banking team where
                            he
                            was involved in several Equity Private Placements and IPOs in the growth tech space. Deals
                            include Paytm, OYO and other late stage growth tech companies.
                            Pursottam did his undergraduate from IIT Kharagpur and MBA from IIM Ahmedabad.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="modal fade" id='People_modal5' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Tessa-Thomas1.png" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Tessa Thomas</h1>
                        <label>Senior Manager - Flipkart Venturess</label>
                        <br><a href="http://www.linkedin.com/in/tessa-thomas-2017/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Tessa joined Flipkart in 2020 in the Corporate Strategy function where she worked on
                            Fintech,
                            Advertising, and Customer Strategy.
                            As part of Flipkart Ventures, she focuses on early-stage investments in Fintech,
                            ClimateTech,
                            and E-commerce Enablement.
                            Prior to Flipkart, she worked across multiple roles ranging from business development, and
                            category management to strategy consulting in both early and mid-stage start-ups like
                            Headout
                            and Razorpay.
                            She holds an MBA from ISB, Hyderabad, and an undergraduate degree in Economics (Hons) from
                            St.Stephen's College, Delhi.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="modal fade" id='People_modal3' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/bhargav.jpg" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Bhargav Teja</h1>
                        <label>Manager - Flipkart Ventures</label>
                        <br><a href="https://www.linkedin.com/in/bhargavtejak/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Bhargav has joined Flipkart Ventures in 2024 and is currently managing LEAP ahead, an Early stage accelerator program of Flipkart. Always curious to learn anything related to Tech, Investments and Innovations. Worked at EY in M&A Valuations & Modeling and as Investments Manager at Brand Capital, an investments arm of Times Group. Completed undergrad in Electrical Engineering from SRM University and MBA from IIM Kozhikode
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id='People_modal4' style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="col-md-12 col-sm-12 modal-body">
                    <span class="close" data-dismiss="modal">×</span>
                    <div class="col-md-4 col-sm-12 leader-modal_img">
                        <img src="/assets/images/Mayank.jpeg" alt="leader-image">
                    </div>
                    <div class="col-md-8 col-sm-12 leader-modal_text text-left">
                        <h1>Mayank Jain</h1>
                        <label>Flipkart Ventures</label>
                        <br><a href="https://www.linkedin.com/in/jain-mayank/" target="_blank"><i
                            class="fab fa-linkedin" style="font-size: 30px;"></i></a>
                        <div>Mayank joined Flipkart in 2024 as part of the Corporate Development and Ventures team. He focuses on early-stage investments and strategic partnerships across sectors. Prior to Flipkart, he worked across finance, audit, and advisory roles with organizations like Go Airlines and Grant Thornton. Mayank holds an MBA from MDI Gurgaon and is a qualified Chartered Accountant (ACCA, UK).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
