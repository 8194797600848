import {Component, OnInit} from '@angular/core';

// import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    slides = [
        {
            img: "/assets/images/ventures-news.webp",
            publish_date: "FEB 20, 2025",
            heading: "Flipkart Ventures selects five startups for its accelerator programme",
            link: "https://economictimes.indiatimes.com/tech/technology/flipkart-ventures-selects-five-startups-for-its-accelerator-programme/articleshow/118416642.cms"
        },
        {
            img: "/assets/images/MoU.jpeg",
            publish_date: "DEC 09, 2024",
            heading: "Flipkart inks MoU with DPIIT to invest in and mentor Indian startups",
            link: "https://pib.gov.in/PressReleaseIframePage.aspx?PRID=2082464"
        },
        {
            img: "/assets/images/story-3.png",
            publish_date: "SEP 25, 2023",
            heading: "Flipkart Ventures to invest in five early-stage startups",
            link: "https://economictimes.indiatimes.com/tech/startups/flipkart-ventures-invests-in-five-early-stage-tech-startups/articleshow/103932709.cms"
        },
        {
            img: "https://stories.flipkart.com/wp-content/uploads/2021/08/FKVentures_RaviIyer_Banner_v3.jpg",
            publish_date: "JULY 26, 2021",
            heading: "With its investment in G.O.A.T Brand Labs, Flipkart Ventures is backing next-generation innovations",
            link: "https://stories.flipkart.com/flipkart-ventures-corporate-development-ravi-iyer/"
        },

    ];

    imagesSlider = {
        "speed": 300,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "cssEase": 'linear',
        "fade": true,
        "autoplay": true,
        "arrows": true,
        "draggable": true,
        "dots": true,
        // "prevArrow":'.client-feedback .prev-arrow',
        // "nextArrow":'.client-feedback .next-arrow',
        // "asNavFor":".thumbs",
    };

    // thumbnailsSlider = {
    //   "speed":300,
    //   "slidesToShow":3,
    //   "slidesToScroll":1,
    //   "cssEase":'linear',
    //   "autoplay": true,
    //   "centerMode":false,
    //   "draggable":false,
    //   "arrows": true,
    //   "focusOnSelect":true,
    //   "asNavFor":".feedback",
    //   responsive: [
    //     {
    //         breakpoint: 1024,
    //         settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 3,
    //         }
    //     },
    //     {
    //         breakpoint: 600,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1
    //         }
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1
    //         }
    //     }
    //   ],
    // };


    investmentSlides = [
        {
            img: "/assets/images/finbox1.png",
            heading: "Finbox",
            description: "Founded by Rajat Deshpande, Anant Deshpande, Nikhil Bhawsinka, and Srijan Nagar, FinBox is a B2B credit risk management platform with proprietary data connectors that assists in lending to self-employed merchants, non-banking financial companies (NBFCs), and new-to-credit customers.",
            link: "https://finbox.in/"
        },
        {
            img: "/assets/images/ventures-BoatO.jpg",
            heading: "BeatO",
            description: "Founded by Gautam Chopra, Yash Sehgal and Kunal Kinalekar, BeatO is a digital app-based platform that provides clinically proven, comprehensive diabetes care programs to prevent, control & reverse diabetes under the guidance of experts. BeatO’s ecosystem includes its innovative smartphone-connected glucometers that work in unison with the BeatO app to provide personalized insights and 24X7 access to an experienced team of medical experts - health coaches, nutritionists and top doctors. BeatO also provides a complete one-stop solution for all daily diabetes essentials such as medicines, lab tests, diabetes friendly foods & products.",
            link: "https://www.beatoapp.com/"
        },
        {
            img: "/assets/images/goat.jpg",
            heading: "G.O.A.T brand labs",
            description: "G.O.A.T Brand Labs is a Bengaluru-based startup that accelerates the growth of D2C brands.  Founded by former Flipkart executive Rishi Vasudev and former SVP at VOONIK, Rameswar Misra, and backed by an experienced team that brings deep expertise in brand building, digital marketing, fashion design, M&A, and operations at scale. G.O.A.T intends to fuel the growth of D2C brands across all channels — marketplaces, owned e-commerce platforms, as well as global markets, by providing a tech-enabled platform for end-to-end integration replete with deep digital marketing expertise.",
            link: "https://goatbrandlabs.com/"
        },
        {
            img: "/assets/images/hyperface.jpeg",
            heading: "Hyperface",
            description: "Founded in 2021 by Ramanathan and veteran banker Aishwarya Jaishankar, Hyperface allows businesses to launch credit cards and pay later issuance products at scale through backend application programming interface (API)-integrations. For businesses, the launch of a credit card programme today takes somewhere up to 12-18 months for a brand. Hyperface claims to take away all the technical gruntwork of backend API integrations and red tape to reduce time-to-launch 10X. With Hyperface, brands can custom-build their own credit programme in a matter of weeks.",
            link: "https://www.hyperface.co/"
        },
    ];

    investmentSlider = {
        "speed": 300,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "cssEase": 'linear',
        "fade": false,
        "autoplay": true,
        "draggable": true,
        "arrows": true,
        // "prevArrow":'.prev-arrow',
        // "nextArrow":'<div class="class-to-style"><span class="fa fa-angle-right"></span><span class="sr-only">Next</span></div>',
    };

    constructor() {
    }

    ngOnInit(): void {
    }
}
