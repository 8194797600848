<div class="col-md-12 col-sm-12 footer">
    <div class="col-md-4 col-sm-2 text-right footer-image">
        <img src="https://flipkartads.azureedge.net/flipkartads/FKlogo.png"/>
    </div>
    <div class="col-md-2 col-sm-2 footer-listing">
        <ul>
            <li><a href="/about">About</a></li>
            <li><a href="/people">People</a></li>
            <li><a href="/investments">Investments</a></li>
            <li><a href="/terms">Terms Of Use</a></li>
            <li><a href="/privacy">Privacy</a></li>
        </ul>
    </div>
    <div class="col-md-2 col-sm-2 footer-listing">
        <ul>
            <li>support@flipkart.com</li>
            <li>ventures@flipkart.com</li>
        </ul>
    </div>
    <div class="col-md-4 col-sm-6 text-right footer-pitch">
        <a href="mailto:ventures@flipkart.com">Pitch to Us</a>
    </div>
</div>