<div class="container-fluid margin-top-10 text-center">
    <h4>OUR INVESTMENTS</h4>
    <h1>We partner with founders and teams who are building next generation innovations</h1>
    <img src="/assets/images/investment_image.png"/>
</div>
<div class="col-md-12 text-center">
    <div id="myBtnContainer">
        <button class="button act" (click)="PeopleFilter('all')"> All</button>
        <button class="button" (click)="PeopleFilter('portfolio')"> Venture Investments</button>
        <button class="button" (click)="PeopleFilter('leap')"> Leap Ahead</button>
        <!-- <button class="button" (click)="PeopleFilter('finance')"> Finance</button>
        <button class="button" (click)="PeopleFilter('people_support')"> People / Support Functions</button> -->
    </div>

    <div class="container">
        <div class="heading" *ngIf="isShown">
            <!-- <p>Portfolio</p> -->
            <h2>Venture Investments</h2>
            <p>Investments in seed to series B stage companies</p>
        </div>
        <div class="filterDiv portfolio">
            <div class="filterImage">
                <img src="/assets/images/BoatO.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>BoatO</h3>
                    <!-- <p>Portfolio Engagement</p> -->
                </span>
                <span><a href="/investment-details/1"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv portfolio">
            <div class="filterImage">
                <img src="/assets/images/Finbox.jpeg"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>Finbox</h3>
                    <!-- <p>Portfolio Engagement</p> -->
                </span>
                <span><a href="/investment-details/2"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv portfolio">
            <div class="filterImage">
                <img src="/assets/images/goat-logo.jpeg"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>GOAT brand labs</h3>
                    <!-- <p>Portfolio Engagement</p> -->
                </span>
                <span><a href="/investment-details/3"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv portfolio">
            <div class="filterImage">
                <img src="/assets/images/hyperface.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>Hyperface</h3>
                    <!-- <p>Portfolio Engagement</p> -->
                </span>
                <span><a href="/investment-details/4"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
    </div>
    <div class="container">
            <div class="heading" *ngIf="isShown">
                <!-- <p>Portfolio</p> -->
                <h2>Leap Ahead</h2>
                <p>Cohort based investments in pre-seed to seed stage companies</p>
                <!-- <a href="https://www.flipkartleap.com/"><img src="/assets/images/view-all-arrow.png"></a> -->
                <a href="https://www.flipkartleap.com/"><span><img src="/assets/images/view-all-arrow.png"></span>Know More</a>
            </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <img src="/assets/images/Algomage.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>Algomage</h3>
                    <p>Leap Ahead Cohort 2</p>
                </span>
                <span><a href="https://algomage.com/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <img src="/assets/images/castler.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>Castler</h3>
                    <p>Leap Ahead Cohort 2</p>
                </span>
                <span><a href="https://castler.com/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
<!--                <img src="https://www.flipkartleap.com/wp-content/uploads/2022/08/dopplr-logo.jpg"/>-->
                <img src="/assets/images/Dopplr.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>Dopplr</h3>
                    <p>Leap Ahead Cohort 1</p>
                </span>
                <span><a href="https://dopplr.digital/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <img src="/assets/images/FlexifyMe.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>FlexifyMe</h3>
                    <p>Leap Ahead Cohort 2</p>
                </span>
                <span><a href="https://www.flexifyme.com/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <!--<img src="https://www.flipkartleap.com/wp-content/uploads/2022/08/Livwell-logo.jpg"/>-->
                <img src="/assets/images/LivWell.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>LivWell</h3>
                    <p>Leap Ahead Cohort 1</p>
                </span>
                <span><a href="https://www.livwell.asia/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <!--<img src="https://www.flipkartleap.com/wp-content/uploads/2022/08/Lorri-logo.png"/>-->
                <img src="/assets/images/Lorri.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>LoRRI</h3>
                    <p>Leap Ahead Cohort 1</p>
                </span>
                <span><a href="https://www.lorri.in/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <img src="/assets/images/NimbleBox.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>NimbleBox.ai</h3>
                    <p>Leap Ahead Cohort 2</p>
                </span>
                <span><a href="https://nimblebox.ai/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <!--<img src="https://www.flipkartleap.com/wp-content/uploads/2022/08/NeuroPixel-logo.jpg"/>-->
                <img src="/assets/images/NeuroPixel.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>NeuroPixel.AI</h3>
                    <p>Leap Ahead Cohort 1</p>
                </span>
                <span><a href="https://www.neuropixel.ai/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <!--<img src="https://www.flipkartleap.com/wp-content/uploads/2022/08/rightbot-logo.jpg"/>-->
                <img src="/assets/images/RightBot.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>Rightbot</h3>
                    <p>Leap Ahead Cohort 1</p>
                </span>
                <span><a href="https://www.rightbot.com/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <img src="/assets/images/ReCircle.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>ReCircle</h3>
                    <p>Leap Ahead Cohort 2</p>
                </span>
                <span><a href="https://recircle.in/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv leap">
            <div class="filterImage">
                <!--<img src="https://www.flipkartleap.com/wp-content/uploads/2022/08/sellerapp-logo.jpg"/>-->
                <img src="/assets/images/Sellerapp.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>SellerApp</h3>
                    <p>Leap Ahead Cohort 1</p>
                </span>
                <span><a href="https://www.sellerapp.com/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
<!--        <div class="filterDiv leap">
            <div class="filterImage">
                <img src="/assets/images/SkyeAir.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>SkyeAir</h3>
                    <p>Leap Ahead Cohort 2</p>
                </span>
                <span><a href="https://www.skyeair.tech/"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>-->
        <!-- <div class="filterDiv finance">
            <div class="filterImage">
                <img src="https://goatbrandlabs.com/wp-content/uploads/2021/09/GOAT-Logo.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>GOAT brand labs</h3>
                    <p>Finance</p>
                </span>
                <span><a href="#"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv finance">
            <div class="filterImage">
                <img src="https://goatbrandlabs.com/wp-content/uploads/2021/09/GOAT-Logo.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>GOAT brand labs</h3>
                    <p>Finance</p>
                </span>
                <span><a href="#"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv people_support">
            <div class="filterImage">
                <img src="https://goatbrandlabs.com/wp-content/uploads/2021/09/GOAT-Logo.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>GOAT brand labs</h3>
                    <p>People / Support Functions</p>
                </span>
                <span><a href="#"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div>
        <div class="filterDiv people_support">
            <div class="filterImage">
                <img src="https://goatbrandlabs.com/wp-content/uploads/2021/09/GOAT-Logo.png"/>
            </div>
            <div class="filterContent">
                <span>
                    <h3>GOAT brand labs</h3>
                    <p>People / Support Functions</p>
                </span>
                <span><a href="#"><img src="/assets/images/view-all-arrow.png"></a></span>
            </div>
        </div> -->
    </div>
</div>
